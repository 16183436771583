import {
    FogbenderProvider, FogbenderConfig, FogbenderIsConfigured,
    FogbenderHeadlessWidget, FogbenderFloatingWidget,
} from "fogbender-react";

const widgetId = import.meta.env.PUBLIC_FOGBENDER_WIDGET_ID ?? '';
const widgetKey = import.meta.env.PUBLIC_FOGBENDER_WIDGET_KEY ?? '';

export default function Fogbender() {
    const token = {
        widgetId,
        widgetKey
    };

    return (
        <FogbenderProvider>
            <FogbenderConfig token={token}/>
            <FogbenderIsConfigured>
                <FogbenderHeadlessWidget/>
                <FogbenderFloatingWidget/>
            </FogbenderIsConfigured>
        </FogbenderProvider>
    )
}